import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import { useRouter } from "vue-router";
// import r from "../router/index";
// import { useRoute } from "vue-router";

const router = useRouter();

export default function () {
  function navigateTo(item) {
    if (item.name) {
      router.push(item);
    }
  }

  function hasSelectedItem(arr) {
    var x = false;
    arr.every((element) => {
      if (element.isSelected) {
        x = true;
        return false;
      }
      return true;
    });

    return x;
  }

  function NotifyUser(data) {
    Notify.create({
      type: data.success ? "positive" : "negative",
      message: data.message,
      html: true,
    });
  }

  function formatDate(date_time) {
    if (date_time === null) return "";
    return moment(date_time).format("YYYY-MM-DD HH:mm:ss");
  }

  function wrapCsvValue(val, formatFn) {
    let formatted = formatFn !== void 0 ? formatFn(val) : val;

    formatted =
      formatted === void 0 || formatted === null ? "" : String(formatted);

    formatted = formatted.split('"').join('""');
    /**
     * Excel accepts \n and \r in strings, but some other CSV parsers do not
     * Uncomment the next two lines to escape new lines
     */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`;
  }

  function getMoment() {
    return moment();
  }

  return {
    getMoment,
    navigateTo,
    hasSelectedItem,
    NotifyUser,
    formatDate,
    wrapCsvValue,
  };
}
